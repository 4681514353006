import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/Sidebar";
import sidebar_menu from "../constants/sidebar-menu";

export default function Layout() {
  return (
    <div>
      <div className="dashboard-container">
        <SideBar menu={sidebar_menu} />
        <div className="dashboard-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
