import React, { useState, useEffect } from "react";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import axios from "axios";

function Payment() {
  const [search, setSearch] = useState("");
  const [payment, setPayment] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    axios
      .get("https://amartoto.in/api/user/plan_transactions")
      .then((response) => {
        console.log(response.data.data);
        setPagination(calculateRange(response.data.data, 20));
        setPayment(sliceData(response.data.data, page, 20));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [page]);

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = payment.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.product.toLowerCase().includes(search.toLowerCase())
      );
      setPayment(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setPayment(sliceData(all_orders, new_page, 5));
  };

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Payments</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search.."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>PlanTrans ID</th>
            <th>Driver ID</th>
            <th>Plan ID</th>
            <th>DriverName</th>
            <th>Mobile</th>
            <th>PlanName</th>
            <th>Validity</th>
            <th>PaymentMode</th>
            <th>UpiTxnID</th>
            <th>StartDate</th>
            <th>ExpiryDate</th>
            <th>Amount</th>
          </thead>

          {payment && payment.length !== 0 ? (
            <tbody>
              {payment.map((data, index) => (
                <tr key={index}>
                  <td>
                    <span>{data.PlanTransID}</span>
                  </td>
                  <td>
                    <span>{data.DriverID}</span>
                  </td>
                  <td>
                    <span>{data.PlanID}</span>
                  </td>
                  <td>
                    <span>{data.DriverName}</span>
                  </td>
                  <td>
                    <span>{data.Mobile}</span>
                  </td>
                  <td>
                    <span>{data.PlanName}</span>
                  </td>
                  <td>
                    <span>{data.Validity}</span>
                  </td>
                  <td>
                    <span>{data.PaymentMode}</span>
                  </td>
                  <td>
                    <span>{data.UpiTxnID}</span>
                  </td>
                  <td>
                    <span>{data.StartDate}</span>
                  </td>
                  <td>
                    <span>{data.ExpiryDate}</span>
                  </td>
                  <td>
                    <span>{data.Amount}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {payment && payment.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
