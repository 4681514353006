import React, { useState, useEffect } from "react";

import "../styles.css";
import axios from "axios";

function DashBoard() {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("https://amartoto.in/api/user/dashboard")
      .then((response) => {
        console.log(response.data.data[0]);
        setData(response.data.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Welcome to Amar Toto</h2>
        </div>

        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Registered Driver</h5>
                  <p className="card-text">{data && data.NumberOfDrivers}</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Registered Users</h5>
                  <p className="card-text">{data && data.NumberOfPassengers}</p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Total Amount Received</h5>
                  <p className="card-text">{data && data.TotalAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
