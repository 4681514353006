import DashboardIcon from "../assets/icons/dashboard.svg";
import ProductIcon from "../assets/icons/product.svg";
import UserIcon from "../assets/icons/user.svg";

const sidebar_menu = [
  {
    id: 1,
    icon: DashboardIcon,
    path: "/",
    title: "Dashboard",
  },
  {
    id: 2,
    icon: ProductIcon,
    path: "/drivers",
    title: "Drivers",
  },
  {
    id: 3,
    icon: UserIcon,
    path: "/users",
    title: "Users",
  },

  {
    id: 4,
    icon: UserIcon,
    path: "/payments",
    title: "Payment",
  },
  {
    id: 5,
    icon: UserIcon,
    path: "/subscriptions",
    title: "Subscriptions",
  },
];

export default sidebar_menu;
