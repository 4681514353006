import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../layout";

export default function ProtectedRoutes() {
  const token = sessionStorage.getItem("isLogin");
  if (token) {
    return <Layout />;
  } else {
    return <Navigate to="/login" />;
  }
}
