import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Login from "./pages/Login";
import Drivers from "./pages/Drivers";

import Payment from "./pages/Payments";
import DashBoard from "./pages/DashBoard";
import Subscriptions from "./pages/Subscriptions";
import Users from "./pages/Users";
import ProtectedRoutes from "./utils/ProtectedRoutes";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<ProtectedRoutes />}>
          <Route exact path="/" element={<DashBoard />} />
          <Route exact path="/drivers" element={<Drivers />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/payments" element={<Payment />} />
          <Route exact path="/subscriptions" element={<Subscriptions />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
