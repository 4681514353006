import React, { useState, useEffect } from "react";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import axios from "axios";

function Drivers() {
  const [search, setSearch] = useState("");
  const [driver, setDriver] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    axios
      .get("https://amartoto.in/api/driver/all")
      .then((response) => {
        setPagination(calculateRange(response.data.data, 15));
        setDriver(sliceData(response.data.data, page, 15));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [page]);

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = driver.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name.toLowerCase().includes(search.toLowerCase()) ||
          item.product.toLowerCase().includes(search.toLowerCase())
      );
      setDriver(search_results);
    } else {
      __handleChangePage(1);
    }
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setDriver(sliceData(all_orders, new_page, 5));
  };

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Driver List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search.."
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>

        <table>
          <thead>
            <th>Driver ID</th>
            <th>Driver Name</th>
            <th>Mobile</th>
            <th>CreatedDate</th>
            <th>LastModifyDate</th>
            <th>IsAvailable</th>
            <th>IsActiveDriver</th>
            <th>latitude</th>
            <th>longitude</th>
          </thead>

          {driver && driver.length !== 0 ? (
            <tbody>
              {driver.map((data, index) => (
                <tr key={index}>
                  <td>
                    <span>{data.DriverID}</span>
                  </td>
                  <td>
                    <span>{data.DriverName}</span>
                  </td>
                  <td>
                    <span>{data.Mobile}</span>
                  </td>
                  <td>
                    <span>{data.CreatedDate}</span>
                  </td>
                  <td>
                    <span>{data.LastModifyDate}</span>
                  </td>
                  <td>
                    <span>{data.IsAvailable}</span>
                  </td>
                  <td>
                    <span>{data.IsActiveDriver}</span>
                  </td>
                  <td>
                    <span>{data.latitude}</span>
                  </td>
                  <td>
                    <span>{data.longitude}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {driver && driver.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Drivers;
